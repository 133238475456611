@value headerHeight from '~Styles/vars/size.css';

.root {
  display: grid;
  grid-template-columns: 72px 100px 80px 150px 44px 70px 70px 80px 80px 64px 93px 24px;
  grid-gap: 20px;
  align-items: center;
  padding: 3px 20px;
  border-top: 1px solid #F2F2F2;
  border-bottom: 1px solid #F2F2F2;
  background-color: #ffffff;
  position: sticky;
  top: headerHeight;
}