.root {
  display: grid;
  grid-template-columns: 72px 100px 80px 150px 44px 70px 70px 80px 80px 64px 93px 24px;
  grid-gap: 20px;
  padding: 16px 20px;
  border-bottom: 1px solid #F2F2F2;
  background-color: #ffffff;
  align-items: flex-start;

  &:hover {
    z-index: 100;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border-color: colorWhite;

    .verifyBtn {
      opacity: 0.5;

      &.isVerified {
        opacity: 1;
      }
    }
  }

  &.failed {
    background: #feeded;

    &:hover {
      border-color: #feeded;
    }
  }
}

.cuttedText {
  @mixin cuttedText;
}

.link {
  width: 100%;
}

.verifyBtn {
  opacity: 0;

  &.isVerified {
    opacity: 1;
  }
}